import React, { useState, useEffect } from 'react';
import api from './../../api/api';
import { Link } from 'react-router-dom';
import Msg from './../../util/msg';

const ArtistaLista = () => {
    const [artistas, setArtistas] = useState([]);
    const [filtro, setFiltro] = useState("");

    const carregaArtistas = async () => {
        const response = await api.get('artistas.php?search=' + filtro);
        return response.data.data;
    }

    const handleInput = (e) => {
        setFiltro(e.target.value);
    }

    const handleExcluir = (id) => {
        Msg.confirm("Deseja excluir este artista?", () => {
            api.delete('artista_excluir.php?id=' + id).then(() => {
                return carregaArtistas();
            }).then((clientes) => {
                setArtistas(clientes);
            });
        })
    }
    
    useEffect(() => {
        const getArtistas = async () => {
            const todosArtistas = await carregaArtistas();
            if (todosArtistas) setArtistas(todosArtistas);
        }
        getArtistas();
    }, [filtro])

    return (
        <>
            <div className='mt-2 mb-2'>
                <Link to="/artistas/novo" className='btn btn-success'>Novo Artista</Link>
                <div class="input-group mt-3 mb-3">
                    <input type="text" className="form-control" value={filtro} onChange={handleInput} placeholder='Pesquisa'/>
                </div>

            </div>
            <div className='table-responsive'>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Nome Artístico</th>
                            <th>Nome Completo</th>
                            <th>Whatsapp</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {artistas.map(cli => (
                            <tr key={cli.id}>
                                <td>{cli.nomeartistico}</td>
                                <td>{cli.nome}</td>
                                <td>{cli.whatsapp}</td>
                                <td>
                                    <Link to={"/artistas/" + cli.id} 
                                        className="btn btn-sm btn-primary me-1">Editar</Link>
                                    <button  onClick={() => handleExcluir(cli.id)}
                                        className='btn btn-sm btn-danger'>Excluir</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )

}

export default ArtistaLista;
