import axios from 'axios';

const api = axios.create({
    baseURL: 'https://avip.itisoft.com.br/api/'//'http://localhost:86/'
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    });

api.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {        
            window.location.href = '/login';
        }
            return Promise.reject(error);
        }
    );

export default api;