import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import Menu from './components/Menu';
import './App.css';
import PrivateRoute from './PrivateRoute';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ClienteLista from './pages/Clientes/ClienteLista';
import ClienteRegistro from './pages/Clientes/ClienteRegistro';
import ArtistaLista from './pages/Artistas/ArtistaLista';
import ArtistaRegistro from './pages/Artistas/ArtistaRegistro';
import AgendaLista from './pages/Agendas/AgendaLista';
import AgendaRegistro from './pages/Agendas/AgendaRegistro';


function App() {
  
  
  return (
    <>
      <AuthProvider>
        <Router>
          <Menu />
          <div className="container">
            <Routes>
              <Route path='/' element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
              <Route path='/login' element={<Login/>}/>
              <Route path='/clientes' element={<PrivateRoute><ClienteLista/></PrivateRoute>}/>
              <Route path='/clientes/novo' element={<PrivateRoute><ClienteRegistro/></PrivateRoute>}/>
              <Route path='/clientes/:id' element={<PrivateRoute><ClienteRegistro/></PrivateRoute>}/>
              <Route path='/artistas' element={<PrivateRoute><ArtistaLista/></PrivateRoute>}/>
              <Route path='/artistas/novo' element={<PrivateRoute><ArtistaRegistro/></PrivateRoute>}/>
              <Route path='/artistas/:id' element={<PrivateRoute><ArtistaRegistro/></PrivateRoute>}/>
              <Route path='/agenda' element={<PrivateRoute><AgendaLista/></PrivateRoute>}/>
              <Route path='/agenda/novo' element={<PrivateRoute><AgendaRegistro/></PrivateRoute>}/>
              <Route path='/agenda/:id' element={<PrivateRoute><AgendaRegistro/></PrivateRoute>}/>
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </>
    
  );
}

export default App;
