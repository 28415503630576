import Swal from "sweetalert2";

const Msg = {
  success: (message, callback = undefined) => {
    Swal.fire({ title: message, icon: 'success' })
      .then(() => {
        if (callback)
          callback()
      });
  },
  error: (message, callback = undefined) => {
    Swal.fire({ title: message, icon: 'error' })
      .then(() => {
        if (callback)
          callback()
      });
  },
  confirm: (message, confirmCallback = undefined, cancelCallback = undefined) => {
    Swal.fire({
      title: message,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        if (confirmCallback) confirmCallback();
      } else {
        if (cancelCallback) cancelCallback();
      }
    });
  },
};

export default Msg;
