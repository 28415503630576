import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { formatDocument } from '../../util/masks';
import api from '../../api/api';
import { formatTelefone } from './../../util/masks';
import Msg from './../../util/msg';

const artistaPadrao = {
    id: 0,
    nome: '',
    nomeartistico: '',
    cnpjcpf: '',
    fone: '',
    whatsapp: '',
    email: '',
    endereco: '',
    numero: '',
    bairro: '',
    cidade: '',
    complemento: '',
    info: ''
}

const ArtistaRegistro = () => {
    const [ artista, setArtista] = useState(artistaPadrao);
    const navigate = useNavigate();
    let { id } = useParams();

    const inputTextHandler = (e) => {
        const {name, value} = e.target;
        setArtista({...artista, [name]: value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await api.post('/artista_salvar.php', artista);

            if (result.data.success) {
                Msg.success(result.data.message, () => navigate('/artistas'));
            } else {
                Msg.error(result.data.message);
            }
        } catch (error) {
            Msg.error(error);
        }
    }

    useEffect(() => {

        const getArtista = async () => {
            
            try {
                const result = await api.get('/artista.php?id=' + id);
                setArtista(result.data.data);
            } catch (error) {
                console.log(error);
            }
            
            
        }

        if (id)
            getArtista();
        
    }, []);

    return (
        <>
            <form className='mt-2' autoComplete='off' onSubmit={handleSubmit}>
                <h4>Artista {id}</h4>
                <div className='row'>
                    <div className='col-md-5 mt-3'>
                        <label className='form-label text-danger' >Nome</label>
                        <input className='form-control' required name="nome" value={artista.nome} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-4 mt-3'>
                        <label className='form-label text-danger'>Nome Artístico</label>
                        <input className='form-control' required name="nomeartistico" value={artista.nomeartistico} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-3 mt-3'>
                        <label className='form-label'>CPF/CNPJ</label>
                        <input className='form-control' name="cnpjcpf" value={formatDocument(artista.cnpjcpf)} 
                            onChange={inputTextHandler} />
                    </div>
                </div>
                <div className='row'>
                    
                    <div className='col-md-3 mt-3'>
                        <label className='form-label'>Fone</label>
                        <input className='form-control' name="fone" value={formatTelefone(artista.fone)} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-3 mt-3'>
                        <label className='form-label'>WhatsApp</label>
                        <input className='form-control' name="whatsapp" value={formatTelefone(artista.whatsapp)} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-6 mt-3'>
                        <label className='form-label'>E-mail</label>
                        <input className='form-control' type='email' name="email" value={artista.email} 
                            onChange={inputTextHandler} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-4 mt-3'>
                        <label className='form-label'>Endereço</label>
                        <input className='form-control' name="endereco" value={artista.endereco} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-1 mt-3'>
                        <label className='form-label'>Nro</label>
                        <input className='form-control' name="numero" value={artista.numero} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-3 mt-3'>
                        <label className='form-label'>Bairro</label>
                        <input className='form-control' name="bairro" value={artista.bairro} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-4 mt-3'>
                        <label className='form-label'>Cidade</label>
                        <input className='form-control' name="cidade" value={artista.cidade} 
                            onChange={inputTextHandler} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-7 mt-3'>
                        <label className='form-label'>Complemento</label>
                        <input className='form-control' name="complemento" value={artista.complemento} 
                            onChange={inputTextHandler} />
                    </div>
                </div>
                <div className='mt-3'>
                    <label className='form-label'>Informações</label>
                    <textarea className='form-control' name="info" 
                        onChange={inputTextHandler} value={artista.info} />
                </div>
                <div className='mt-3 mb-4'>
                    <button type='submit' className='btn btn-primary me-2'>Salvar</button>
                    <Link className='btn btn-secondary' to='/artistas'>Lista de Artistas</Link>
                </div>
            </form>
        </>
    )

}

export default ArtistaRegistro;