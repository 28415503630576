import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Login = () => {
    const { login } = useContext(AuthContext);
    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        const logado = await login({ usuario, senha });
        if (logado)
            navigate("/clientes");
        else
            Swal.fire('Usuário ou senha incorretos');
    }

    return (
        <>
            <div className='d-flex justify-content-center align-items-center'>
                <div className="card mt-5 col-md-6 col-sm-10 col-11">
                    <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <div className="mt-3">
                            <label className="form-label">Usuário</label>
                            <input className="form-control" required value={usuario} onChange={(e) => setUsuario(e.target.value)} />
                        </div>
                        <div className="mt-3">
                            <label className="form-label">Senha</label>
                            <input type="password" className="form-control" required value={senha} onChange={(e) => setSenha(e.target.value)} />
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-primary">Confimar</button>
                    </div>
                    </form>
                </div>
            </div>
        </>
    )

}

export default Login;