

const Dashboard = () => {
    return (
        <>
            <h3 className="mt-3">Bem-vindo ao Sistema</h3>
        </>
    )

}

export default Dashboard;