import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { formatDocument } from '../../util/masks';
import api from '../../api/api';
import { formatTelefone } from './../../util/masks';
import Msg from './../../util/msg';

const clientePadrao = {
    id: 0,
    nome: '',
    razao: '',
    cnpjcpf: '',
    fone: '',
    whatsapp: '',
    email: '',
    endereco: '',
    numero: '',
    bairro: '',
    cidade: '',
    complemento: '',
    classificacao: 1,
    info: ''
}

const ClienteRegistro = () => {
    const [ cliente, setCliente] = useState(clientePadrao);
    const [ classificacoes, setClassificacoes] = useState([]);
    const navigate = useNavigate();
    let { id } = useParams();

    const inputTextHandler = (e) => {
        const {name, value} = e.target;
        setCliente({...cliente, [name]: value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await api.post('/cliente_salvar.php', cliente);

            if (result.data.success) {
                Msg.success(result.data.message, () => navigate('/clientes'));
            } else {
                Msg.error(result.data.message);
            }
        } catch (error) {
            Msg.error(error);
        }
    }

    useEffect(() => {

        const getCliente = async () => {
            
            try {
                const result = await api.get('/cliente.php?id=' + id);
                setCliente(result.data.data);
            } catch (error) {
                console.log(error);
            }
            
            
        }

        const getClassificacoes = async () => {
            try {
                const result = await api.get('/clientes_classificacoes.php');
                setClassificacoes(result.data.data);
            } catch (error) {
                console.log(error);
            }
        }

        getClassificacoes();

        if (id)
            getCliente();
        
    }, []);

    return (
        <>
            <form className='mt-2' autoComplete='off' onSubmit={handleSubmit}>
                <h4>Cliente {id}</h4>
                <div className='row'>
                    <div className='col-md-5 mt-3'>
                        <label className='form-label text-danger' >Nome</label>
                        <input className='form-control' required name="nome" value={cliente.nome} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-4 mt-3'>
                        <label className='form-label'>Razão Social</label>
                        <input className='form-control' name="razao" value={cliente.razao} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-3 mt-3'>
                        <label className='form-label'>CPF/CNPJ</label>
                        <input className='form-control' name="cnpjcpf" value={formatDocument(cliente.cnpjcpf)} 
                            onChange={inputTextHandler} />
                    </div>
                </div>
                <div className='row'>
                    
                    <div className='col-md-3 mt-3'>
                        <label className='form-label'>Fone</label>
                        <input className='form-control' name="fone" value={formatTelefone(cliente.fone)} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-3 mt-3'>
                        <label className='form-label'>WhatsApp</label>
                        <input className='form-control' name="whatsapp" value={formatTelefone(cliente.whatsapp)} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-6 mt-3'>
                        <label className='form-label'>E-mail</label>
                        <input className='form-control' type='email' name="email" value={cliente.email} 
                            onChange={inputTextHandler} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-4 mt-3'>
                        <label className='form-label'>Endereço</label>
                        <input className='form-control' name="endereco" value={cliente.endereco} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-1 mt-3'>
                        <label className='form-label'>Nro</label>
                        <input className='form-control' name="numero" value={cliente.numero} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-3 mt-3'>
                        <label className='form-label'>Bairro</label>
                        <input className='form-control' name="bairro" value={cliente.bairro} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-4 mt-3'>
                        <label className='form-label'>Cidade</label>
                        <input className='form-control' name="cidade" value={cliente.cidade} 
                            onChange={inputTextHandler} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-7 mt-3'>
                        <label className='form-label'>Complemento</label>
                        <input className='form-control' name="complemento" value={cliente.complemento} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-5 mt-3'>
                    <label className='form-label'>Classificação</label>
                        <select className='form-select' name="classificacao" value={cliente.classificacao} 
                            onChange={inputTextHandler} >
                                {classificacoes.map(cla => (
                                    <option value={cla.id}>
                                        {cla.descricao}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>
                <div className='mt-3'>
                    <label className='form-label'>Informações</label>
                    <textarea className='form-control' name="info" 
                        onChange={inputTextHandler} value={cliente.info} />
                </div>
                <div className='mt-3 mb-4'>
                    <button type='submit' className='btn btn-primary me-2'>Salvar</button>
                    <Link className='btn btn-secondary' to='/clientes'>Lista de Clientes</Link>
                </div>
            </form>
        </>
    )

}

export default ClienteRegistro;