import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

const Menu = () => {
  const { user, logout } = useContext(AuthContext);
  const getActiveRoute = useLocation().pathname ? 'Active' : '';

  const handleSairClick = () => {
    logout();
  }

  return (
    <>
      {user &&
      <Navbar bg="secondary" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand as={NavLink} className={getActiveRoute}  
            to="/">AVIP</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={NavLink} 
                className={getActiveRoute} 
                to="/clientes">Clientes</Nav.Link>
              <Nav.Link as={NavLink} 
                className={getActiveRoute} 
                to="/artistas">Artistas</Nav.Link>
              <Nav.Link as={NavLink} 
                className={getActiveRoute} 
                to="/agenda">Agenda</Nav.Link>
              <Nav.Link as={NavLink} 
                className={getActiveRoute} 
                to="/financeiro">Financeiro</Nav.Link>
            </Nav>
            <Nav>
              <NavDropdown align='end' title={user.usuario} id="basic-nav-dropdown">
                  <NavDropdown.Item href="">Perfil</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleSairClick}>
                      Sair
                  </NavDropdown.Item>
              </NavDropdown>
              </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>}
    </>
  )
}

export default Menu;