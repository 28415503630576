import React, { useState, useEffect } from 'react';
import api from './../../api/api';
import { Link } from 'react-router-dom';
import Msg from './../../util/msg';
import { format } from 'date-fns';

const AgendaLista = () => {
    const [agendas, setAgendas] = useState([]);
    const [dataInicial, setDataInicial] = useState(new Date().toISOString().split('T')[0]);
    const [dataFinal, setDataFinal] = useState();
    const [filtro, setFiltro] = useState("");

    const carregaAgendas = async () => {
        let url = "agendas.php?search=" + filtro;

        if (dataInicial)
            url += "&&data_inicial=" + dataInicial;

        if (dataFinal)
            url += "&&data_final=" + dataFinal;

        const response = await api.get(url);
        return response.data.data;
    }

    const handleInput = (e) => {
        setFiltro(e.target.value);
    }

    const handleExcluir = (id) => {
        Msg.confirm("Deseja excluir esta agenda?", () => {
            api.delete('agenda_excluir.php?id=' + id).then(() => {
                return carregaAgendas();
            }).then((agendas) => {
                setAgendas(agendas);
            });
        });

        
    }
    
    useEffect(() => {
        const getAgendas = async () => {
            const todos = await carregaAgendas();
            if (todos) setAgendas(todos);
        }
        getAgendas();
    }, [filtro, dataInicial, dataFinal])

    return (
        <>
            <div className='mt-2 mb-2'>
                <Link to="/agenda/novo" className='btn btn-success'>Nova Agenda</Link>
                <div class="row">
                    <div class="col-md-3 mt-3">
                        <label className="form-label">Data Inicial</label>
                        <input type='date' className='form-control' value={dataInicial} onChange={(event) => setDataInicial(event.target.value)} placeholder='Data Inicial' />
                    </div>
                    <div class="col-md-3 mt-3">
                        <label className='form-label'>Data Final</label>
                        <input type='date' className='form-control' value={dataFinal} onChange={(event) => setDataFinal(event.target.value)} placeholder='Data Final' />
                    </div>
                </div>
                <div class="input-group mt-3 mb-3">
                    <input type="text" className="form-control" value={filtro} onChange={handleInput} placeholder='Pesquisa'/>
                </div>

            </div>
            <div className='table-responsive'>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Data Hora</th>
                            <th>Artista</th>
                            <th>Cliente</th>
                            <th>Local</th>
                            <th>Cidade</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {agendas.map(age => (
                            <tr key={age.id}>
                                <td>{format(new Date(age.datahora), 'dd/MM/yyyy HH:mm:ss')}</td>
                                <td>{age.artista_nome}</td>
                                <td>{age.cliente_nome}</td>
                                <td>{age.local}</td>
                                <td>{age.cidade}</td>
                                <td>
                                    <Link to={"/agenda/" + age.id} 
                                        className="btn btn-sm btn-primary me-1">Editar</Link>
                                    <button  onClick={() => handleExcluir(age.id)}
                                        className='btn btn-sm btn-danger'>Excluir</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )

}

export default AgendaLista;