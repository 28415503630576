import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import api from '../../api/api';
import Msg from './../../util/msg';
import Select from 'react-select';

const agendaPadrao = {
    id: 0,
    artista: 0,
    cliente: 0,
    datahora: null,
    local: '',
    endereco: '',
    cidade: '',
    info: ''
}

const AgendaRegistro = () => {
    const [ agenda, setAgenda] = useState(agendaPadrao);
    const [ selectedArtista, setSelectedArtista] = useState();
    const [ selectedCliente, setSelectedCliente] = useState();
    const [ artistas, setArtistas] = useState([]);
    const [ clientes, setClientes] = useState([]);
    const navigate = useNavigate();
    let { id } = useParams();

    const inputTextHandler = (e) => {
        const {name, value} = e.target;
        setAgenda({...agenda, [name]: value});
        console.log(agenda);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await api.post('/agenda_salvar.php', agenda);

            if (result.data.success) {
                Msg.success(result.data.message, () => navigate('/agenda'));
            } else {
                Msg.error(result.data.message);
            }
        } catch (error) {
            Msg.error(error);
        }
    }

    useEffect(() => {

        const getAgenda = async () => {
            
            try {
                const result = await api.get('/agenda.php?id=' + id);
                setAgenda(result.data.data);
            } catch (error) {
                console.log(error);
            }
            
            
        }

        const getArtistas = async () => {
            try {
                const result = await api.get('/artistas.php');
                setArtistas(result.data.data);
            } catch (error) {
                console.log(error);
            }
        }

        const getClientes = async () => {
            try {
                const result = await api.get('/clientes.php');
                setClientes(result.data.data);
            } catch (error) {
                console.log(error);
            }
        }

        getArtistas();
        getClientes();
        

        if (id)
            getAgenda();
        
    }, []);

    const artistasOptions = artistas.map(artista => ({
            value: artista.id,
            label: artista.nomeartistico,
        }));
    
    const clientesOptions = clientes.map(cliente => ({
        value: cliente.id,
        label: cliente.nome,
    }));

      useEffect(() => {
        if (agenda.artista) {
          const artistaSelecionado = artistasOptions.find(artista => artista.value === agenda.artista);
          setSelectedArtista(artistaSelecionado);
        }
      }, [agenda.artista]);

      useEffect(() => {
        if (agenda.cliente) {
          const clienteSelecionado = clientesOptions.find(cliente => cliente.value === agenda.cliente);
          setSelectedCliente(clienteSelecionado);
        }
      }, [agenda.cliente]);

    return (
        <>
            <form className='mt-2' autoComplete='off' onSubmit={handleSubmit}>
                <h4>Agenda {id}</h4>
                <div className='row'>
                    <div className='col-md-3 mt-3'>
                        <label className='form-label text-danger' >Data Hora</label>
                        <input className='form-control' type="datetime-local" required name="datahora" value={agenda.datahora} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-5 mt-3'>
                        <label className='form-label text-danger' >Artista</label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            options={artistasOptions}
                            value={selectedArtista}
                            onChange={(selectedOption) => {
                                inputTextHandler({ target: { name: 'artista', value: selectedOption.value } });
                            }}
                            placeholder='Selecione um artista'
                        />
                    </div>
                    <div className='col-md-4 mt-3'>
                        <label className='form-label text-danger' >Cliente</label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            options={clientesOptions}
                            value={selectedCliente}
                            onChange={(selectedOption) => {
                                inputTextHandler({ target: { name: 'cliente', value: selectedOption.value } });
                            }}
                            placeholder='Selecione um cliente'
                        />
                    </div>

                    
                </div>
                <div className='row'>
                    <div className='col-md-4 mt-3'>
                        <label className='form-label'>Local</label>
                        <input className='form-control' name="local" value={agenda.local} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-5 mt-3'>
                        <label className='form-label'>Endereço</label>
                        <input className='form-control' name="endereco" value={agenda.endereco} 
                            onChange={inputTextHandler} />
                    </div>
                    <div className='col-md-3 mt-3'>
                        <label className='form-label'>Cidade</label>
                        <input className='form-control' name="cidade" value={agenda.cidade} 
                            onChange={inputTextHandler} />
                    </div>
                </div>
                <div className='mt-3'>
                    <label className='form-label'>Informações</label>
                    <textarea className='form-control' name="info" 
                        onChange={inputTextHandler} value={agenda.info} />
                </div>
                <div className='mt-3 mb-4'>
                    <button type='submit' className='btn btn-primary me-2'>Salvar</button>
                    <Link className='btn btn-secondary' to='/agenda'>Lista de Agenda</Link>
                </div>
            </form>
        </>
    )

}

export default AgendaRegistro;