import React, { createContext, useState } from 'react';
import api from '../api/api';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = async (userData) => {
    try {
        const response = await api.post('login.php', userData);
        const user = response.data.data;
        
        localStorage.setItem('token', user.token);

        setUser(user);
        return user;
    } catch (error) {
        console.error(error);
        return null;
    }
  };

  const logout = () => {
    localStorage.setItem('token', '');
    setUser(null);
  };
  
  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };